<template>
  <div class="hsm-col">
    <input
      type="text"
      class="form-control"
      disabled
      :value="walletAddress"
    >
  </div>
</template>

<script>

export default {
  name: 'CpHsmWallet',
  props: {
    walletId: {
      type: Number,
      default: 0,
    },
    walletAddress: {
      type: String,
      default: '',
    },
  },
};
</script>
<style>
  .hsm-row {
    display: flex;
    margin-bottom: 20px;
  }
  .hsm-col {
    flex: 1
  }
</style>
