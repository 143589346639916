<template>
  <div class="card mb-5">
    <div class="card-header">
      <div class="hsm-row">
        <div class="hsm-col">
          <h4 class="mb-0 text-primary">
            {{ $t('operators.hsm.pageTitle') }}
          </h4>
        </div>
      </div>
    </div>
    <div
      class="card-body"
    >
      <div class="hsm-row">
        <div class="hsm-col">
          <label class="mr-4">
            {{ $t('operators.hsm.toggleLabel') }}
          </label>
          <cp-switch
            name="enabled"
            :value="operator.isEnabled"
            @input="handleToggleStatus"
          />
        </div>
      </div>
      <div
        v-if="isLoading"
        class="row align-items-center mb-2 spinner"
      >
        <div class="col-md-12 text-center">
          <b-spinner class="align-middle" />
        </div>
      </div>
      <div v-else>
        <div
          v-for="(address, key) in operator.keys"
          :key="key"
        >
          <div
            class="hsm-row"
          >
            <div class="hsm-col">
              <label class="mr-4">
                {{ $t('operators.hsm.walletLabel') }} {{ key + 1 }}
              </label>
            </div>
            <cp-hsm-wallet
              :id="key"
              :wallet-id="key"
              :wallet-address="address"
            />
            <div
              class="hsm-col"
            >
              <button
                v-if="key === operator.keys.length - 1"
                class="btn btn-default ml-4 mt-sm-1"
                @click="handleCreateKey"
              >
                <i class="ion ion-ios-add" />
              </button>
              <button
                v-if="key !== 0"
                class="btn btn-default ml-4 mt-sm-1"
                @click="handleRemoveKey(address)"
              >
                <i class="ion ion-ios-trash" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import { mapActions } from 'vuex';
  import CpSwitch from '../../../../components/common/standalone-components/inputs/cp-switch';
  import CpHsmWallet from './cp-hsm-wallet';

  export default {
    name: 'CpHsmInfo',
    components: {
      CpHsmWallet,
      CpSwitch,
    },
    data() {
      return {
        isLoading: true,
        operator: {
          id: '',
          keys: [],
          isEnabled: false,
          isCreated: false,
        },
      };
    },
    async created() {
      await this.initialize();
    },
    methods: {
      ...mapActions({
        getOperatorHSMKeys: 'operators/getOperatorHSMKeys',
        createHSMOperator: 'operators/createHSMOperator',
        removeHSMOperatorKey: 'operators/removeHSMOperatorKey',
        createHSMOperatorKey: 'operators/createHSMOperatorKey',
        toggleHSMOperatorStatus: 'operators/toggleHSMOperatorStatus',
      }),
      async initialize() {
        this.isLoading = true;
        this.operator.id = this.$route.params.operatorId;
        const { data } = await this.getOperatorHSMKeys({ operatorId: this.operator.id });

        this.operator.isCreated = !!data.operatorId;
        this.operator.keys = data.operatorKeys;
        this.operator.isEnabled = this.operator.isCreated && data.status;

        this.isLoading = false;
      },
      async register() {
        this.createOperator()
            .then(() => this.createKey())
            .then(() => this.initialize())
            .catch(err => this.handleError(this.$('operator.hsm.error.register'), err));
      },
      async createOperator() {
        await this.createHSMOperator({ operatorId: this.operator.id });
      },
      async createKey() {
        await this.createHSMOperatorKey({ operatorId: this.operator.id });
      },
      async removeKey(hsmAddress) {
        await this.removeHSMOperatorKey({ operatorId: this.operator.id, hsmAddress });
      },
      async toggleStatus() {
        await this.toggleHSMOperatorStatus({ operatorId: this.operator.id });
      },
      async handleCreateKey() {
        this.isLoading = true;

        this.createKey()
          .then(() => this.initialize())
            .catch(err => this.handleError(this.$('operator.hsm.error.createKey'), err));
      },
      async handleRemoveKey(hsmAddress) {
        this.isLoading = true;

        this.removeKey(hsmAddress)
          .then(() => this.initialize())
            .catch(err => this.handleError(this.$('operator.hsm.error.removeOperator'), err));
      },
      async handleToggleStatus() {
        this.isLoading = true;

        if (!this.operator.isCreated) {
          await this.register();
        } else {
          this.toggleStatus()
            .then(() => this.initialize())
              .catch(err => this.handleError(this.$('operator.hsm.error.toggleStatus'), err.message));
        }
      },
      async handleError(message, err) {
        this.$log.error(message, err);
        await this.initialize();
      },
    },
  };
</script>
<style>
  .hsm-row {
    display: flex;
    margin-bottom: 20px;
    flex-direction: row;
  }
  .hsm-col {
    flex: 1
  }
</style>
