<template>
  <div>
    <div class="row align-items-center mb-4">
      <b-button
        class="btn btn-outline-primary btn-sm"
        @click="goPageBack"
      >
        <i class="ion ion-ios-arrow-back" />
        {{ $t('operators.hsm.backToPage') }}
      </b-button>
    </div>
    <cp-hsm-info />
  </div>
</template>
<script>
import CpHsmInfo from './components/cp-hsm-info';

export default {
  name: 'CpHsmPanel',
  components: {
    CpHsmInfo,
  },
  metaInfo: {
    title: 'HSM Wallet Info',
  },
  props: {
  },
  methods: {
    goPageBack() {
      window.history.back();
    },
  },
};
</script>
